import React, {useEffect, useRef, useState} from 'react';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';
import Request from './components/Request';
import Ongoing from './components/Ongoing';
import Scheduled from './components/Scheduled';
import History from './components/History';
import Settings from './components/Settings';
import RequestPage from './components/RequestPage';
import Login from './components/Login';
import { GlobalStateProvider } from './state';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faChevronDown,
  faWallet,
  faSignOutAlt,
  faComment,
  faArchive,
  faMapMarkerAlt,
  faCog,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {CookieConsent} from "react-cookie-consent";
import {useTranslation} from "react-i18next";

library.add(
  faUser,
  faChevronDown,
  faWallet,
  faSignOutAlt,
  faComment,
  faArchive,
  faMapMarkerAlt,
  faCog,
  faSpinner
);

function App() {

  const { t } = useTranslation();
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const cookieConsentRef = useRef(null);

  useEffect(() => {
    if (cookieConsentRef.current) {
      const observer = new MutationObserver(() => {
        const acceptCookieButton = cookieConsentRef.current.querySelector('#rcc-confirm-button');
        const cookieBannerDiv = cookieConsentRef.current.querySelector('#cookie-banner');
        if(acceptCookieButton && cookieBannerDiv) {
          cookieBannerDiv.prepend(acceptCookieButton);
        }
      });

      observer.observe(cookieConsentRef.current, { childList: true });

      return () => {
        observer.disconnect(); // Cleanup when component unmounts
      };
    }
  }, []);


  return (
    <Router>
      <GlobalStateProvider>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route>
            <Header />
            <div className="page-content">
              <Switch>
                <PrivateRoute>
                  <Route path="/history">
                    <History />
                  </Route>
                  <Route path="/scheduled">
                    <Scheduled />
                  </Route>
                  <Route path="/ongoing">
                    <Ongoing />
                  </Route>
                  <Route path="/settings">
                    <Settings />
                  </Route>
                  <Route path="/jobs/:id">
                    <RequestPage />
                  </Route>
                  <Route path="/" exact>
                    <Request />
                  </Route>
                </PrivateRoute>
              </Switch>
            </div>
          </Route>
        </Switch>
        <div ref={cookieConsentRef}>
          <CookieConsent
              location="bottom"
              buttonText={t('cookiesClose')}
              cookieName="xleds-cookies-consent"
              contentClasses="text-center"
              disableButtonStyles={true}
              buttonClasses='btn btn-secondary'
          >
              <div className='w-100'>
                {
                  showMoreInfo ?
                      <>
                        <h4>{t('cookiesMoreInfoTitle')}</h4>
                        <p>{t('cookiesMoreInfoText')}</p>
                      </> :
                     <h6>{t('siteUsesOnlyMandatoryCookies')}</h6>
                }
              </div>
              <div id="cookie-banner" className='text-center mt-2'>
                {!showMoreInfo && <button onClick={() => setShowMoreInfo(true)} className="btn btn-light ml-2">{t('learnMore')}</button>}
              </div>
          </CookieConsent>
        </div>
      </GlobalStateProvider>
    </Router>
  );
}

export default App;
